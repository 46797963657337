import React, { useState } from "react";
import { InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import DstButton from "../../components/DstButton/DstButton";
import DstLiveTable from "../../components/DstTable/DstLiveTable";
import ListerSearch from "../../components/Lister/ListerSearch/ListerSearch";
import MultiFilterDropdown from "../../components/MultiFilterDropdown/MultiFilterDropdown";
import { useSession } from "../../contexts/SessionContext";
import { EvaluationAttempt } from "../../models/EvaluationAttempt";
import { computeElapsedTime, computeRemainingTime, formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import { completeNavigate } from "../../services/UtilityService";
import { format, getStatusColor, languages, statuses } from "./EvaluationAttempts.function";

const EvaluationAttempts = () => {
  const { language } = useSession();
  const navigate = useNavigate();
  const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(["CORRECTION_PENDING"]);
  const [selectedUserLanguages, setSelectedUserLanguages] = useState<string[]>([]);
  const [selectedCustomerTypes, setSelectedCustomerTypes] = useState<string[]>([]);
  const [selectedIntensities, setSelectedIntensities] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const _generateRemoteUrl = () => {
    const filters = [];
    if (selectedStatuses.length > 0) {
      filters.push(`status=${selectedStatuses.join(",")}`);
    }
    if (selectedIntensities.length > 0) {
      filters.push(`format=${selectedIntensities.join(",")}`);
    }
    if (selectedUserLanguages.length > 0) {
      filters.push(`language=${selectedUserLanguages.join(",")}`);
    }
    if (searchQuery) {
      filters.push(`search=${searchQuery.toLowerCase()}`);
    }

    return `evaluations/attempts${filters.length > 0 ? `?${filters.join("&")}` : ""}`;
  };

  const columns = [
    {
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.EXAM_NAME"),
      key: "evaluation.name",
      render: (item: EvaluationAttempt) => {
        let iconClass = "dst-icon-message-question-square";
        switch (item.evaluation.type.toLowerCase()) {
          case "notebook":
            iconClass = "dst-icon-file-code-01";
            break;
          case "upload":
            iconClass = "dst-icon-paperclip";
            break;
          case "html":
            iconClass = "dst-icon-code-02";
            break;
        }
        return (
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <i className={`${iconClass} me-2`} />
              {item.evaluation.name || "N/A"}
            </div>
          </div>
        );
      },
    },
    {
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.DEADLINE.TITLE"),
      key: "deadline",
      render: (item: EvaluationAttempt) => {
        let deadlineText = "";
        let deadlineClass = "";

        if (item.status.toLowerCase() === "correction_pending" && item.deadline) {
          const [time, date] = item.deadline.split(" ");
          const [day, month, year] = date.split("/").map(Number);
          const [hours, minutes] = time.split(":").map(Number);
          const deadline = new Date(year, month - 1, day, hours, minutes);

          if (!isNaN(deadline.getTime())) {
            const now = new Date();

            if (deadline.getTime() < now.getTime()) {
              deadlineText = computeElapsedTime(language, deadline.toISOString(), "TIME.", "_LATE");
              deadlineClass = "text-danger";
            } else {
              deadlineText = computeRemainingTime(language, deadline.toISOString(), "TIME.", "_LEFT");
            }
          } else {
            deadlineText = translate(language, "PAGES.ATTEMPTS.COLUMNS.DEADLINE.INVALID_DATE");
          }
        }

        return (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              {item.deadline || "N/A"}
              {item.status.toLowerCase() === "correction_pending" && item.deadline && (
                <div className={`${deadlineClass} ms-2`}>{deadlineText}</div>
              )}
            </div>
            <OverlayTrigger
              key="overlay-trigger-deadline-info"
              placement="bottom"
              overlay={
                <Tooltip aria-label="Extra time">
                  {translate(language, "PAGES.ATTEMPTS.COLUMNS.DEADLINE.EXPLAIN")}
                </Tooltip>
              }
            >
              <i className="clickable dst-icon-info-circle" />
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.END_DATE.TITLE"),
      key: "dt_ended",
      sortable: true,
      render: (item: EvaluationAttempt) => {
        if (item.dt_ended) {
          const [time, date] = item.dt_ended.split(" ");
          const [day, month, year] = date.split("/").map(Number);
          const [hours, minutes] = time.split(":").map(Number);
          const dtEnded = new Date(year, month - 1, day, hours, minutes);

          if (!isNaN(dtEnded.getTime())) {
            return (
              <div className="d-flex align-items-center">{formatDateHumanly(language, dtEnded.toISOString())}</div>
            );
          } else {
            return translate(language, "PAGES.ATTEMPTS.COLUMNS.END_DATE.INVALID_DATE");
          }
        } else {
          return "N/A";
        }
      },
    },
    {
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.STATUS"),
      key: "status",
      render: (item: EvaluationAttempt) => <span className={getStatusColor(item.status)}>{item.status || "N/A"}</span>,
    },
    {
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.AI_PREVISION"),
      key: "ai_prevision",
      render: (item: EvaluationAttempt) => item.ai_prevision_score || "N/A",
    },
    {
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.LEARNERS"),
      key: "user.full_name",
      render: (item: EvaluationAttempt) => (
        <div className="d-flex align-items-center">
          {item.user.full_name} <i className="dst-icon-link-01 ms-1" />
        </div>
      ),
      onCellClick: (event: React.MouseEvent, item: EvaluationAttempt) =>
        completeNavigate(event, navigate, `/user/${item.user.id}`),
    },
  ];
  return (
    <React.Fragment>
      <h1 className="p-3">{translate(language, "PAGES.ATTEMPTS.TITLE")}</h1>
      <hr />
      <div className="px-3">
        <div className="d-flex mb-3 justify-content-between">
          <div className="d-flex">
            {/* <div className="me-2">
              <MultiFilterDropdown
                tags={programs}
                onSelectTags={(elem) => setSelectedPrograms(elem as string[])}
                showSearchBar
                inputTitle={translate(language, "PAGES.ATTEMPTS.FILTER.PROGRAM")}
                language={language}
              />
            </div>
            <div className="me-2">
              <MultiFilterDropdown
                tags={customerTypes}
                onSelectTags={(elem) => setSelectedCustomerTypes(elem as string[])}
                showSearchBar
                inputTitle={translate(language, "PAGES.ATTEMPTS.FILTER.CUSTOMER_SEGMENT")}
                language={language}
              />
            </div> */}
            <div className="me-2">
              <MultiFilterDropdown
                tags={format}
                onSelectTags={(elem) => setSelectedIntensities(elem as string[])}
                showSearchBar
                inputTitle={translate(language, "PAGES.ATTEMPTS.FILTER.FORMAT")}
                language={language}
              />
            </div>
            <div className="me-2">
              <MultiFilterDropdown
                tags={languages}
                onSelectTags={(elem) => setSelectedUserLanguages(elem as string[])}
                showSearchBar
                inputTitle={translate(language, "PAGES.ATTEMPTS.FILTER.LANGUAGE")}
                language={language}
              />
            </div>
            <div className="me-2">
              <MultiFilterDropdown
                tags={statuses}
                defaultValue={["CORRECTION_PENDING"]}
                onSelectTags={(elem) => setSelectedStatuses(elem as string[])}
                showSearchBar
                inputTitle={translate(language, "PAGES.ATTEMPTS.FILTER.STATUS")}
                language={language}
              />
            </div>
          </div>
          <DstButton
            variant="light"
            btnImageBefore={<span className="dst-icon-settings-04 me-1" />}
            value={translate(language, "PAGES.ATTEMPTS.ACTIONS.MY_FILTER")}
            clickFunction={() => alert("WIP")}
          />
        </div>
        <InputGroup>
          <ListerSearch setSearchQuery={setSearchQuery} timeDelay={500} showSearchIcon />
        </InputGroup>
        <div className="d-flex align-items-center justify-content-end xs-regular">
          {/* {selectedItems.length > 0 && (
            <div
              className="me-2 clickable dark-gray-font text-decoration-underline"
              onClick={() => setSelectedItems([])}
            >
              {translate(language, "PAGES.ATTEMPTS.ACTIONS.CANCEL_SELECTION")}
            </div>
          )}
          <div className="me-2 electric-blue-font">
            {selectedItems.length} {translate(language, "PAGES.ATTEMPTS.CORRECTION_SELECTED")}
          </div>
          <DstButton
            btnClass="me-2"
            variant="light"
            btnImageBefore={<span className="dst-icon-tool-02 me-1" />}
            value={translate(language, "PAGES.ATTEMPTS.ACTIONS.SET_IN_PROGRESS")}
            clickFunction={() => alert("WIP")}
          />
          <DstButton
            btnClass="me-2"
            variant="light"
            btnImageBefore={<span className="dst-icon-flag-04 me-1" />}
            value={translate(language, "PAGES.ATTEMPTS.ACTIONS.SET_ON_HOLD")}
            clickFunction={() => alert("WIP")}
          /> */}
          <DstButton
            btnClass="me-2"
            variant="light"
            btnImageBefore={<span className="dst-icon-download-01 me-1" />}
            value={translate(language, "PAGES.ATTEMPTS.ACTIONS.DOWNLOAD_FILE")}
            clickFunction={() => alert("WIP")}
          />
        </div>
        <div className="d-flex">
          {/* <p className="xs-regular dark-gray-font mb-0">
            {translate(language, "PAGES.ATTEMPTS.MATCH_FILTER")
              .replace("{{NUMBER_ATTEMPTS_FILTER}}", filteredData.length.toString())
              .replace("{{NUMBER_ATTEMPTS_TOTAL}}", evaluationAttempts.length.toString())}
          </p> */}
          {(selectedPrograms.length > 0 ||
            selectedStatuses.length > 0 ||
            selectedUserLanguages.length > 0 ||
            selectedCustomerTypes.length > 0 ||
            selectedIntensities.length > 0) && (
            <div
              className="ms-3 clickable dark-gray-font text-decoration-underline xs-regular"
              onClick={() => {
                setSelectedPrograms([]);
                setSelectedStatuses([]);
                setSelectedUserLanguages([]);
                setSelectedCustomerTypes([]);
                setSelectedIntensities([]);
              }}
            >
              {translate(language, "PAGES.ATTEMPTS.ACTIONS.CLEAR_ALL_FILTER")}
            </div>
          )}
        </div>
        <DstLiveTable
          remote={_generateRemoteUrl()}
          remoteKey="evaluation_attempts_list"
          columns={columns}
          onRowClick={(event, item: any) =>
            ["CREATED", "IN_PROGRESS"].includes(item.status)
              ? null
              : completeNavigate(event, navigate, `/evaluation-attempt/${item.id}`)
          }
          pagination
          itemsPerPage={20}
          paginationAlwaysShowFooter
        />
      </div>
    </React.Fragment>
  );
};
export default EvaluationAttempts;
